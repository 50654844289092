<template>
<div>
  <Header></Header>
  <div class="game_details">
    <div class="title">
      <router-link :to="{name:'AllGame',query:{s:this.$store.state.selectToken}}">首页</router-link>
      <router-link
        :to="this.$route.query.game_id == '26'?{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'yuanshen5',s:this.$store.state.selectToken}}:{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'games',s:this.$store.state.selectToken}}">
        &emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;{{ game_name }}
      </router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;账号详情</a>
    </div>
    <div v-if="show" class="details">
      <div class="details_info">
        <div class="DI_base_img">
          <div slot="reference">
            <el-image
              style="width: 520px"
              :style="accountInfo.id_api_shangjia?'':'height:290px'"
              :src="accountInfo.image"
              fit="cover"
              :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                图片加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="DI_base_content">
          <div class="DI_prodct_price">
            <div id="DI_prodct_price" v-if="accountInfo.price > 0">
              商品价格：
              <div>¥{{ accountInfo.price }}</div>
              <img src="@/assets/image/AllGame/isSign.png" alt="">
            </div>
            <!-- <div id="DI_prodct_hot">
              <img src="@/assets/image/Home/topGamesIco.png" alt="">
              <span>{{ accountInfo.click }}</span>
            </div> -->
          </div>
          <div class="DI_prodct_zone">
            <div v-for="(categoryItem,index) in accountInfo.category" :key="index" id="DI_prodct_zone" >
              <div class="zone_title">
                {{ categoryItem.name }}：
              </div>
              <div class="zone_content">{{ categoryItem.value }}</div>
            </div>
          </div>
          <div class="DI_prodct_bottom">
            <div>热度：{{ accountInfo.click }}</div>
            <div>上架时间：{{ accountInfo.up_time | moment }}</div>
          </div>
        </div>
      </div>
      <div class="DI_productName">
        <span :class="accNameHide?'DI_productName_hide':'DI_productName_show'" ref="detailDom">
          <span class="DI_productName_title">{{ accountInfo.game_name }}</span>
          {{ accountInfo.name }}
        </span>
        <div class="DI_productName_btn" v-if="showDetailBtn" @click="accNameHide = !accNameHide">{{accNameHide?'展开全部':'收起文字'}} <i :class="accNameHide?'el-icon-caret-bottom':'el-icon-caret-top'"></i></div>
      </div>
      <div class="details_tabbar">
        <el-tabs v-model="activeName">
          <el-tab-pane label="账号详情" name="first">
            <div class="first_content ql-container ql-snow">
              <p class=" ql-editor" v-html="accountInfo.content"></p>
            </div>
            <div class="first_content ql-container ql-snow">
              <p class=" ql-editor" v-html="accountInfo.cdata"></p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div v-else class="showFalse">
      <div class="showFalse_tip">
        <img src="@/assets/image/AllGame/showFalse.png" alt="">
      </div>
      <div class="showFalse_btnG">
        <router-link id="back" :to="{name:'AllGame',query:{s:this.$store.state.selectToken}}">返回首页</router-link>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
import {apiProductInfo} from "@/request/API";
import Header from '@/components/header'
export default {
  name: '',
  components: { Header },
  data() {
    return {
      //游戏名称
      game_name: '',
      //游戏id
      game_id: this.$route.query.game_id,
      //账号id
      id: this.$route.query.id,
      // 当前选中tabbar
      activeName: 'first',
      // 交易qq群
      qqGrounp: [],
      //账号详情
      accountInfo: {
        image: ''
      },
      can_show: true,
      srcList: [],


      centerDialogVisible: false,
      //账号标题隐藏
      accNameHide:true,
      showDetailBtn:false,
      detailDom:'',

      //qq三国 按服务器
      category15:'',
      category15Img:'',

      //是否显示
      show:true
    }
  },
  methods: {
    //初始化数据
    onLoad() {
      apiProductInfo({
        game_id: this.game_id,
        id: this.id,
      }).then(res => {
        this.can_show = res.data.can_show
        this.accountInfo = res.data
        if(this.accountInfo.status !== 1) this.show = false
        this.srcList.push(res.data.image)
        this.game_name = res.data.game_name
        this.qqGrounp = res.data.game_qun
        if (res.data.game_id == '15') {
          this.accountInfo.category.forEach(item=>{
            if (item.name == '按服务器') {
              this.category15Img = require(`../../../assets/image/AllGame/gameDetailsTip15/广告-5 - ${item.value}.jpg`)
            }
          })
        }
        document.title = this.accountInfo.name
        this.showDetailBtnFun();
      })

    },
    /**
     * 判断文本是否溢出
     * 特殊：如果文字长度刚好达到某个临界值，会出现不及时刷新出现按钮的情况，解决方案：使用this.$nextTick强制刷新
     */
    showDetailBtnFun() {
      this.$nextTick(()=>{
        this.detailDom = this.$refs.detailDom;
        this.showDetailBtn = this.detailDom.clientHeight < this.detailDom.scrollHeight;
      })
    },

  },
  mounted() {
    //初始化数据
    this.onLoad()
    this.detailDom = this.$refs.detailDom;
    this.showDetailBtnFun();
    window.addEventListener('resize', this.showDetailBtnFun);
  },
  watch: {},
  computed: {
  },
  created() {
    document.title = ''
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'GameDetails') {
      document.title = '选号网站'
      next()
    }
  }


}
</script>

<style lang="less">
.first_content img, .second_content img, .third_content img {
  max-width: 100%;
  object-fit: cover;
  background: #D8D8D8;
  border-radius: 20px;
  margin: 0 0 9px 0;
}

.add_price_confirm {
  width: 500px !important;
  height: 300px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;

  .el-message-box__header > .el-message-box__title {
    display: none;
  }

  .el-message-box__header, .el-message-box__content {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }

  .el-message-box__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .el-message-box__title {
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }

  .el-message-box__container {
    margin-top: 63px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }

  .change_price_confirm_btn {
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }

  .change_price_confirm_btn:hover {
    background: #FFDB51 !important;
  }

  .confirm_content {
    display: flex;
    flex-direction: column;
  }

  .confirm_original_price {
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_my_bid {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_modify_bid {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .confirm_modify_bid_text {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }

  .confirm_modify_bid_input {
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }

  .confirm_modify_bid_tips {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }

  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}

.change_price_confirm {
  width: 500px !important;
  height: 320px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;

  .el-message-box__header > .el-message-box__title {
    display: none;
  }

  .el-message-box__header, .el-message-box__content {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }

  .el-message-box__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .el-message-box__title {
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }

  .el-message-box__container {
    margin-top: 34px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }

  .change_price_confirm_btn {
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }

  .change_price_confirm_btn:hover {
    background: #FFDB51 !important;
  }

  .confirm_content {
    display: flex;
    flex-direction: column;
  }

  .confirm_original_price {
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_my_bid {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .confirm_modify_bid {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .confirm_modify_bid_text {
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }

  .confirm_modify_bid_input {
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }

  .confirm_modify_bid_tips {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }

  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}

.contactCusSer {
  width: 500px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 10px 15px 20px;
    position: relative;

    #contactCusSer {
      width: 470px;

      .contactCusSer_title {
        background: #313131;
        color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        height: 33px;
        line-height: 33px;
        font-size: 20px;
      }

      .contactCusSer_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;

        .CL_item {
          width: 450px;
          height: 26px;
          margin: 0 0 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          div {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #000000;
          }

          .CL_btn {
            width: 120px;
            display: flex;
            flex-direction: row;

            div, a {
              margin: 0 10px 0 0;
              font-size: 16px;
              background: rgb(255, 219, 81);
              width: 54px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              border-radius: 7px;
              cursor: pointer;
            }

            a {
              margin: 0;
              background: #f19958;
            }

          }

          #input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -10;
          }
        }
      }

      .onlineCus {
        margin: 20px auto;
        width: 100px;
        text-align: center;
        background-color: rgb(255, 219, 81);
        color: black;
        padding: 10px 18px;
        font-weight: bold;
        font-size: 16px;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        display: block;
      }

      .contactCusSer_tip {
        text-align: center;
        color: black;
        margin: 4px 0 0 0;
        //font-weight: 600;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 14px;

        span {
          display: inline-block;
          color: #FF5454;
        }
      }
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>

<style lang="less" scoped>
.ql-container.ql-snow{
  border: none !important;
}

/deep/ .el-tabs__nav-wrap::after {
  content: none;
}

/deep/ .el-tabs__nav-scroll, /deep/ .el-tabs__nav {
  height: 71px;
  display: flex;
  align-items: flex-start;
}

/deep/ .el-tabs__item {
  height: 71px;
  font-size: 20px;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 300;
  color: #000000;
  line-height: 60px;
  padding: 0 25px !important;
}

/deep/ .el-tabs__header {
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15) !important;
  background: linear-gradient(180deg,#4459a4, #5870c4 100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/deep/ .el-tabs__item.is-active {
  // background: url("../../../assets/image/AllGame/active-barBG.png") no-repeat;
  // background-size: 100% 100%;

  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  // line-height: 70px;
  display: flex;
  align-items: center;
  &::before{
    content: '';
    width: 10px;
    height: 21px;
    display: block;
    background: #fff;
    margin-right: 20px;
  }
}

/deep/ .el-tabs__active-bar.is-top {
  //background: #FFFFFF linear-gradient(90deg, transparent 20px, #FCAD13 20px, #FCAD13 calc(100% - 20px), transparent calc(100% - 20px));
  ////width: 20% !important;
  //padding: 0 20px;
  //box-sizing: border-box;
  //background-clip: content-box;
  ////background: #FCAD13;
  //border-radius: 4px;

  height: 0px;
}

/deep/ .el-tabs__header {
  // margin: 0 0 40px;
  // padding: 0 40px;
  // position: relative;
  // height: 60px;
}

/deep/ .el-tabs__content {
  padding: 0px 40px 58px 40px;
}

.game_details {
  width: 1200px;
  margin: 0 auto 30px;
  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;

      .Breadcrumb {
        font-size: 12px;
      }
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #4B61B0;
      cursor: pointer;
    }
  }

  .details {
    display: flex;
    flex-direction: column;

    .details_info {
      padding: 20px 40px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: row;

      .DI_base_img {
        margin: 0 20px 0 0;
        overflow: hidden;
        height: 290px;
        border-radius: 10px;
      }

      .DI_base_content {
        width: 580px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .DI_prodct_tip {
          height: 32px;
          background: #FF7474;
          padding: 0 20px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 32px;
        }

        .DI_prodct_price {
          height: 62px;
          // background: url("../../../assets/image/AllGame/detailsInfo.png") no-repeat;
          background: #e5edff;
          border: 1px solid #8e9fde;
          border-radius: 4px;
          padding: 0 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          #DI_prodct_price {
            display: flex;
            align-items: center;
            flex-direction: row;
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            margin: 0 10px 0 0;

            div {
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              margin: 0 10px 0 0;
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          #DI_prodct_hot {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;

            img {
              margin: 0 6px 0 0;
              width: 17.38px;
              height: 20px;
            }
          }
        }
        .DI_prodct_bottom{
          border-top: 1px solid #cbcbcb;
          padding: 12px 20px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #5b5b5b;
        }
        .DI_prodct_zone {
          min-height: 50px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          //margin: 10px 0;
          padding: 0 20px;

          #DI_prodct_zone {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0;

            .zone_title {
              max-width: 84px;
              min-width: 56px;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #8F8F8F;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .zone_content {
              width: 118px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              background: url("../../../assets/image/AllGame/gamingZone.png") no-repeat;
            }
          }
        }

        .DI_prodct_promise {
          padding: 10px 0 11px 20px;
          border-top: 1px solid #F1F1F1;
          border-bottom: 1px solid #F1F1F1;
          display: flex;
          flex-direction: row;
          align-items: center;

          .promise_title {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            line-height: 20px;
            margin: 0 16px 0 0;
          }

          .promise_list {
            display: flex;
            flex-direction: row;

            .promise_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #BFBFBF;
              margin: 0 40px 0 0;

              img {
                margin: 0 4px 0 0;
              }
            }

            .promise_item:last-of-type {
              margin: 0;
            }
          }
        }

        .DI_prodct_addDate {
          margin: 11px 0 0 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .times {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #547990;
            padding: 10px 40px 10px 20px;
            background: #E5F5FF;
            border-radius: 10px;
            position: relative;

            .transactions_num {
              font-size: 20px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              line-height: 28px;
            }
          }

          .times::before {
            position: absolute;
            content: '';
            z-index: 1;
            width: 0;
            height: 0;
            top: 14px;
            left: -14px;
            border-top: 10px solid transparent;
            border-right: 20px solid #E5F5FF;
            border-bottom: 10px solid transparent;
          }

          .up_time {
            font-size: 14px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;

            span {
              color: #5B5B5B;
            }
          }

        }
      }
    }

    .DI_btnGroup {
      width: 1120px;
      height: 60px;
      background: #FFFFFF;
      padding: 8px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .DI_btnGroup_left{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 520px;
        .collectBtn,.isCollectBtn,.priceCutBtn{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 40px;
          border-radius: 4px;
          margin: 0 41px 0 0;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #fcad13;
          img {
            margin: 0 10px 0 0;
            height: 36px;
            object-fit: contain;
          }
        }
        .priceCutBtn{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #0054c5;
          img {
            margin: 0 6px 0 0;
          }
        }
        div{
          cursor: pointer;
        }
      }
      .DI_btnGroup_right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 540px);
        div,a{
          cursor: pointer;
        }
        .onlineDealBtn {
          width: 430px;
          height: 60px;
          background: #FFDB51;
          border-radius: 30px;
          font-size: 20px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #000000;
          line-height: 60px;
          text-align: center;
        }
      }
    }

    .DI_productName {
      margin: 10px 0;
      width: 1120px;
      background: #FFFFFF;
      padding: 20px 40px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
      line-height: 34px;
      text-align: justify; /*实现两端对齐*/
      text-justify: newspaper; /*通过增加或减少字或字母之间的空格对齐文本*/
      word-break: break-all; /*允许在单词内换行*/

      .DI_productName_hide,.DI_productName_show{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
        .DI_productName_title {
          background: #CFDDFF;
          border-radius: 20px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #3D59BE;
          padding: 2px 13px;
          margin-right: 5px;
        }
      }
      .DI_productName_show{
        display: block;
      }
      .DI_productName_btn{
        margin: 15px 0 0 ;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #CACACA;
        line-height: 22px;
      }
      .DI_productName_btn:hover{
        color: #FCAD13;
      }
    }

    .DI_links {
      width: 1120px;
      height: 40px;
      background: #FFFFFF;
      padding: 20px 40px;
      margin: 0 0 10px 0;

      .labels {
        display: flex;
        flex-direction: row;

        a, ::v-deep.el-popover__reference {
          flex: 1;
          text-align: center;
          margin: 0 11px 0 0;
          font-size: 16px;
          padding: 11px 10px;
          border-radius: 10px;
          border: 1px solid #ababab;

          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #868686;
        }

        & > span {
          margin-left: 11px;
          cursor: pointer;
          flex: 1;
          display: flex;
        }

        ::v-deep.el-popover__reference-wrapper {
          flex: 1;
          display: flex
        }

        ::v-deep.el-popover__reference:hover {
          color: #FCAD13;
          background: #FFF6E4;
          border: 1px solid #FFF6E4;
        }

        a:last-of-type {
          margin: 0;
        }

        a:hover {
          color: #FCAD13;
          background: #fff6e4;
          border: 1px solid #ffb800;
        }
      }
    }

    .details_tabbar {
      //padding: 40px 40px 58px 40px;
      //padding: 0px 40px 58px 40px;
      background-color: #FFFFFF;

      .Tip {
        position: relative;

        .JL_tip {

        }

        .JL_tipBtn {
          position: absolute;
          bottom: 90px;
          right: 200px;
          cursor: pointer;
        }

        .JL_tipBtn:hover {
          animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          @keyframes header_fourMove {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-2px);
            }
          }
        }
      }
      .Tip12 {
        position: relative;

        .JL_tip {

        }

        .tipBtn {
          position: absolute;
          bottom: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          img:hover {
            cursor: pointer;
            animation: header_fourMove 0.2s linear infinite; // 重点，定义动画
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            @keyframes header_fourMove {
              0% {
                transform: translateY(0);
              }
              100% {
                transform: translateY(-2px);
              }
            }
          }
        }

      }

      .first_content {
        width: 1120px;
        margin-bottom: 9px;
      }

      .second_content {
        width: 1120px;
        margin-bottom: 9px;
      }

      .third_content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .third_content_item {
          margin-bottom: 50px;

          .content_item_title {
            display: flex;
            flex-direction: row;
            margin-bottom: 21px;

            div:first-of-type {
              margin-right: 20px;
            }

            div:last-of-type {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FF9407;
            }
          }

          .content_item_content {
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
            line-height: 25px;

            img {
              width: 1120px;
              height: 128px;
              object-fit: cover;
            }
          }
        }

        .third_content_item:last-of-type {
          margin-bottom: 0;
        }

        .third_content_item:first-of-type {
          margin-bottom: 41px;
        }
      }

      .fourth_content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        .fourth_content_item {
          width: 50%;

          .fourth_content_item_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 15px 0;

            div:first-of-type {
              margin-right: 20px;
            }

            .qq_logo {
              margin-right: 10px;
            }

            .group_name {
              font-size: 20px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #000000;
              line-height: 28px;
              margin-right: 30px;
            }

            .add_group {
              width: 100px;
              height: 40px;
              background: linear-gradient(180deg, #FFDB51 0%, #FF8435 100%);
              border-radius: 20px;
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 40px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .showFalse {
    width: 300px;
    //height: 285px;
    background: #FFFFFF;
    padding: 50px 450px 70px;

    .showFalse_tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 39px 0;

      img {
        width: 229px;
        height: 150px;
        object-fit: cover;
        //margin: 0 0 6px 0;
      }

      #tip {
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #9A9A9A;
        line-height: 20px;
      }
    }

    .showFalse_btnG {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        width: 300px;
        height: 50px;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      #back {
        background: #FFDB51;
        margin: 0 0 8px 0;
      }

      #cusSer {
        border: 1px solid #000000;
      }
    }
  }

}

</style>
